import {URL,axios} from '@/util/request.js';

export function getCode(data){
    return axios.post(URL.API_GETVERIFICATIONCODE,data)
}
export function codeLogin(data){
    return axios.post(URL.API_VERIFITICATIONCODELOGIN,data)
}
export function checkPhone(data){
    return axios.post(URL.API_CHECKPHONE,data)
}
export function register(data){
    return axios.post(URL.API_REGISTER,data)
}
export function login(data){
    return axios.post(URL.API_LOGIN,data)
}
export function resetPassword(data){
    return axios.post(URL.AP_RESETPASSWORD,data)
}