import Axios from 'axios';
import { Message } from 'element-ui';
import { login } from '@/api/user'
import router from '@/router'
require('js-base64').Base64;
console.log(process.env);
const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})
// 请求拦截
axios.interceptors.request.use(
    value => {
        const token = localStorage.getItem('token');
        if (token) { value.headers['token'] =Base64.decode(token)};
        return value;
    },
    error => {
        Message({
            message: error,
            type: error
        })
    }
)

// 响应拦截
axios.interceptors.response.use(
    response => {
        if (response.data != null && response.data['message'] != '401') {
            const data = response.data;
            if (data.status == 200) {
                return data;
            } else {
                Message({
                    message: data.msg,
                    type: 'error'
                })
                return Promise.reject()
            }
        } else if (response.data['message'] == '401') {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (userInfo.password) {
                login({
                    phone: userInfo.phone,
                    password: Base64.decode(userInfo.password)
                }).then(res => {
                    router.go(0);
                    localStorage.setItem("token", Base64.encode(res.data.token));
                })
            }
        } else {
            Message({
                message: '未知错误',
                type: 'error'
            })
            return Promise.reject()
        }
    },
    error => {
        Message({
            message: error,
            type: 'error'
        })
        return Promise.reject()
    }
)


const URL = {
    static: 'http://static.diworks.cn/',
    // 用户
    API_GETVERIFICATIONCODE: 'staff/getVerificationCode',  //获取校验码
    API_VERIFITICATIONCODELOGIN: 'staff/verificationCodeLogin',  //验证码登录
    API_CHECKPHONE: 'staff/checkPhone', // 验证手机号
    API_REGISTER: 'staff/register', //注册
    API_LOGIN: 'staff/login', //密码登录
    AP_RESETPASSWORD: 'staff/resetPassword',  //重置密码

    // 模块
    API_ALLMODULE: 'module/allModule',  //面板
    API_MODULELIST: 'module/moduleList',  //
};


export {
    URL,
    axios
}